.report-container {
    margin-top: 20px;
    text-align: center;
    & table {
        width: 100%;

        border-collapse: collapse;
        border: 1px solid#D3D3D3;
        & th {
            padding: 5px 3px;
            text-align: center;
            border: 1px solid#D3D3D3;
            background-color: #89cff0;
            white-space: pre-line;
        }
        & td {
            padding: 10px;
            text-align: left;
            border: 1px solid#D3D3D3;
        }
        tr:nth-child(odd) {
            background-color: #f2f2f2;
        }

        tr:nth-child(even) {
            background-color: #ffffff;
        }
    }
}

.custom-table {
    overflow-x: auto;
}
.custom-table td {
    border: 2px solid black;
    padding: 0;
    margin: 0px;
    overflow: auto;
}

.custom-table th {
    text-align: center;
    font-size: 14px;
    resize: horizontal;
    overflow: auto;
    height: 50px;
    margin: 0px;
    padding: 0px;
    border: 1px solid black;
    min-width: 20px; /* Minimum width */
    min-height: 20px; /* Minimum height */
}

.custom-table td th {
    border: 0;
    height: auto;
    min-height: 20px;
    min-width: 20px;
}
.report-heading {
    margin-top: 20px;
    text-align: center;
}

@media screen and (min-width: 300px) {
    .report-loading-button {
        margin-top: 70px !important;
        margin-right: 50px !important;
    }
    .report-filter-parent {
        text-align: center;
        justify-content: center;
    }
    .report-filter-button {
        margin-left: 0px;
    }
    .report-filter-checkbox {
        display: flex;
        flex-direction: column;
    }
}
@media screen and (min-width: 1024px) {
    .report-filter-parent {
        text-align: left;
        justify-content: left;
    }
    .report-loading-button {
        margin-top: 0px !important;
        margin-right: 0px !important;
    }
    .report-filter-button {
        margin-left: auto;
    }
    .report-filter-checkbox {
        display: flex;
        flex-direction: row;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1366px) {
    .report-heading {
        margin-left: 20px;
        text-align: left;
        width: 385px;
    }
}
